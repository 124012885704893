import commonHelper from '@/app/utility/common.helper.utility';
import advanceSearch from './advanceSearch';
import DatePicker from 'vue2-datepicker';
import party from '@/app/components/generalLedger/party';
import { ModelSelect } from 'vue-search-select';
import ModulesList from '@/app/components/admin/responsibility/searchModule';
import store from '../../../../../store';
import appStrings from '@/app/utility/string.utility';
import { required } from 'vuelidate/lib/validators';
import addManualPayments from './addManualPayments';
export default {
  name: 'manualPayments',
  components: {
    DatePicker,
    ModelSelect,
    advanceSearch,
    party,
    ModulesList,
    addManualPayments
  },
  watch: {
    currentPage: function() {
      this.getManualPaymentsList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getManualPaymentsList();
    }
  },
  data() {
    return {
      loader: false,
      validateMsg: null,
      unsubscribe: null,
      searchParams: null,
      showModal: false,
      showAlert: false,
      selectedModule: {
        name: null,
        id: null
      },
      isSuccess: false,
      responseMsg: '',
      payload: {},
      totalRows: null,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      showAdvSearchModal: false,
      showVoidBtn: false,
      showVoidModal: false,
      editMode: false,
      selectedReceiptDetails: null,
      voidForm: {
        selectedModule: {
          name: 'Payables',
          id: null
        },
        gl_date: null,
        reason: null,
        selectedLegalEntity: {
          value: null,
          text: null
        }
      },
      showHideModule: false,
      selectedLegalEntity: {
        value: null,
        text: null
      },
      moduleId: null,
      apPaymentHdrId: null,
      party: {
        value: null,
        text: null
      },
      showPartyModal: false,
      vsetCode: null,
      paymentMethod: {
        value: null,
        text: null
      },
      paymentMethodList: [],
      paymentBasedBanks: [],
      showValueSetModal: false,
      tab: null,
      partyType: null,
      from_date_to_date: [],
      showApplyReceiptDetailsForm: false,
      applyReceiptRowData: null,
      manualPaymentsData: [
        {
          invoicesList: []
        },
        {
          accountingList: []
        }
      ],
      manualPaymentsFields: [
        {
          key: 'select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'invoice'
        },
        {
          key: 'accounting'
        },
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'document_num',
          label: 'Payment Doc No.'
        },
        {
          key: 'payment_methods_name',
          label: 'Payment Method'
        },
        {
          key: 'payment_ref_no'
        },
        {
          key: 'party_type'
        },
        {
          key: 'party_name'
        },
        {
          key: 'party_site_id'
        },
        {
          key: 'party_site_name',
          label: 'Party Site'
        },
        {
          key: 'party_site_address'
        },
        {
          key: 'instrument_status_meaning',
          label: 'Instrument Status'
        },
        {
          key: 'payment_amount'
        },
        {
          key: 'currency'
        },
        {
          key: 'exchange_rate'
        },
        {
          key: 'exchange_rate_type'
        },
        {
          key: 'actual_bank_exchange_rate',
          label: 'Bank Exng. Rate'
        },
        {
          key: 'actual_bank_exchange_rate_date',
          label: 'Bank Exng. Rate Date'
        },
        {
          key: 'payment_date'
        },
        {
          key: 'beneficiary_name'
        },
        {
          key: 'bank_account_number',
          label: 'Bank Acc No.'
        },
        {
          key: 'ifsc_code',
          label: 'IFSC Code'
        },
        {
          key: 'utr_no',
          label: 'UTR No.'
        },
        {
          key: 'void_date'
        },
        {
          key: 'void_gl_date',
          label: 'Void GL Date'
        },
        {
          key: 'remarks',
          label: 'Void Reason'
        },
        {
          key: 'request_id',
          label: 'Request'
        }, 
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        },
        {
          key: 'last_update_date'
        },
        {
          key: 'last_updated_by'
        }
      ],
      invoicesList: [],
      invoicesFields: [
        {
          key: 'invoice_number'
        },
        {
          key: 'invoice_date'
        },
        {
          key: 'invoice_amount'
        },
        {
          key: 'schedule_number'
        },
        {
          key: 'schedule_line_type_meaning',
          label: 'Schedule Line Type'
        },
        {
          key: 'schedule_amount'
        },
        {
          key: 'original_amount',
          label: 'Outstanding Amount'
        },
        {
          key: 'payment_amount',
          label: 'Paid in this Payment'
        }
      ],
      project: {
        value: null,
        text: null
      },
      accountingList: [],
      accountingFields: [
        {
          key: 'document_num'
        },
        {
          key: 'accounting_event_type'
        },
        {
          key: 'account_class',
          label: 'Charge Type'
        },
        {
          key: 'ccid',
          label: 'CCID'
        },
        {
          key: 'gl_date',
          label: 'GL Date',
          class: 'text-center'
        },
        {
          key: 'period_name'
        },
        {
          key: 'transaction_dr',
          label: 'Trx Amt DR',
          class: 'text-right'
        },
        {
          key: 'transaction_cr',
          label: 'Trx Amt CR',
          class: 'text-right'
        },
        {
          key: 'base_dr',
          class: 'text-right'
        },
        {
          key: 'base_cr',
          class: 'text-right'
        }
      ],
      showAddManualPaymentModal: false,
      manualPaymentDetails: null,
      paymentDate: null,
      apHdrId :null,
      accountingListData :[],
      invoiceListData:[]

    };
  },
  validations: {
    selectedLegalEntity: {
      value: { required }
    },
    voidForm: {
      gl_date: { required },
      reason: { required }
    }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showAddManualPaymentModal = true;
          this.manualPaymentDetails = null;
        }
        if (actionName === 'save' && this.showVoidModal) {
          if (this.validateMsg === 'OPEN') {
            this.addVoidManualPayment(this.voidForm.ap_payment_hdr_id);
          }
        }
        if (actionName === 'download' && !this.showModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'manualPayments/getManualPaymentGrid',
            'manual-payments',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getManualPaymentsList() {
      // To get manual payment data
      this.$v.$touch();
      if (!this.$v.selectedLegalEntity.$invalid) {
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage
        };
        if (!this.searchParams) {
          this.payload = {
            _page: this.currentPage - 1,
            _limit: this.perPage,
            le_id: this.selectedLegalEntity.value,
            payment_method_id: this.paymentMethod.value,
            payment_from_date: commonHelper.formattedDate(
              this.from_date_to_date[0]
            ),
            payment_to_date: commonHelper.formattedDate(
              this.from_date_to_date[1]
            )
          };
        } else {
          this.payload = Object.assign(this.payload, this.searchParams);
        }
        this.loader = true;
        this.$store
          .dispatch('manualPayments/getManualPaymentGrid', this.payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.manualPaymentsData = response.data.data.page.map(elem => {
                elem.select = false;
                return elem;
              });
              this.totalRows = response.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    addVoidManualPayment(apPaymentHdrId) {
      // To add void payment
      this.$v.$touch();
      if (!this.$v.voidForm.reason.$invalid) {
        const payload = {
          void_reason: this.voidForm.reason,
          void_gl_date: this.voidForm.gl_date,
          payments_hdr_id: apPaymentHdrId
        };
        this.loader = true;
        this.$store
          .dispatch('manualPayments/addVoidManualPayment', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.editMode = false;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      }
    },
    getManualPaymentById(paymentDtl, payments) {
      // To get invoice data
      this.loader = true;
      this.$store
        .dispatch(
          'manualPayments/getManualPaymentById',
          paymentDtl.ap_payment_hdr_id
        )
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            paymentDtl.invoicesList = response.data.data;
            this.invoiceListData = paymentDtl.invoicesList;
            this.accountingListData = [];
            payments.toggleDetails();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openShowModal(flag, component) {
      this.showModal = flag;
      this.tab = component;
    },

    selectedModuleData(module) {
      // To select module
      this.selectedModule.name = module.module_name;
      this.selectedModule.id = module.module_id;
      this.showModal = false;
      this.getPaymentMethodsList();
    },

    getPaymentMethodsList() {
      // To open valueset payment method
      const payload = {
        module_id: this.selectedModule.id == 22 ? 20 : this.selectedModule.id,
        le_id: this.selectedLegalEntity.value,
        project_id: this.project.value
      };
      this.loader = true;
      this.$store
        .dispatch('bankMpngMode/getPaymentMethods', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            const typeList = results.map(type => {
              return {
                value: type.party_payment_method_id,
                text: type.payment_methods_name
              };
            });
            this.paymentMethodList = typeList;
            this.paymentBasedBanks = results;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getSelectedPartyCustomer(item) {
      // To select party customer or vendor
      this.party.text = item.party_name;
      this.partyType = item.party_type;
      this.showPartyModal = false;
      if (this.partyType === 'CUSTOMER') {
        this.getCustomerByPartyId(item.party_id);
      } else if (this.partyType === 'VENDOR') {
        this.getPartyVendorDetails(item.party_id);
      }
    },
    getCustomerByPartyId(partyId) {
      // To get customer
      this.$store
        .dispatch('leases/getCustomerByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.party.value = response.data.data.customer_id;
          }
        });
    },
    getPartyVendorDetails(partyId) {
      // To get vendor
      this.$store
        .dispatch('party/getPartyVenderSiteList', partyId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.party.value = response.data.data.vendor_id;
          }
        });
    },
    showHidePartyModal(flag) {
      // To open party modal
      this.showPartyModal = flag;
      this.setCustCode = setTimeout(() => {
        this.eventBus.$emit('userType', 'CUST');
      }, 0);
    },
    selectPayment(item) {
      this.paymentDate = item.payment_date;
      // To select payment
      this.selectedPaymentDetails = Object.assign({}, item);
      if (!this.selectedPaymentDetails.void) {
        this.showVoidBtn = true;
        this.voidForm = Object.assign(this.voidForm, item);
      } else {
        this.showVoidBtn = false;
      }
    },
    closeValueSetModal() {
      // To close valueset modal
      this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode) {
      // To open valueset modal
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      // To select valueset
      if(this.vsetCode === appStrings.VALUESETTYPE.MANUAL_MODULE_VSET){
        this.selectedModule.name = item.value_code;
        this.selectedModule.id = item.value_set_dtl_id;
        this.getPaymentMethodsList();
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.selectedLegalEntity.text = item.value_code;
        this.selectedLegalEntity.value = item.value_set_dtl_id;
        this.getPaymentMethodsList();
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST ||
        this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT
      ) {
        this.project.text = item.value_code;
        this.project.value = item.value_set_dtl_id;
        this.getPaymentMethodsList();
      }
      this.showValueSetModal = false;
    },
    hideModuleModal(flag) {
      // To open module modal
      this.showHideModule = flag;
    },
    getDefaultDate(moduleId) {
      // To  get gl date
      const payload = {
        module_id: moduleId,
        le_id: this.voidForm.le_id,
        entity_id: this.voidForm.ou_id
      };
      this.loader = true;
      this.$store
        .dispatch('manualPayments/getModuleBasedPeriodDate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.voidForm.gl_date = response.data.data;
            this.getValidatePeriodDate(this.voidForm.gl_date);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getValidatePeriodDate(date) {
      // to get validate date
      const payload = {
        module_id: store.state.shared.moduleId,
        le_id: this.voidForm.le_id,
        entity_id: this.voidForm.ou_id,
        gl_date: date
      };
      this.loader = true;
      this.$store
        .dispatch('manualPayments/getValidatePeriodDate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = `${response.data.message} , GL Date status is ${response.data.data}.`;
            this.validateMsg = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearFilters() {
      // To clear filters
      this.selectedModule.id = null;
      this.selectedModule.name = null;
      this.selectedLegalEntity.text = null;
      this.selectedLegalEntity.value = null;
      this.project.text = null;
      this.project.value = null;
      this.paymentMethod.value = null;
      this.paymentMethod.text = null;
      this.from_date_to_date = [];
      this.manualPaymentsData = [];
      this.showVoidBtn = false;
    },
    advanceSearch(params) {
      this.selectedLegalEntity.value = params.searchParams.le_id;
      this.selectedLegalEntity.text = params.le_name;
      this.selectedModule.name = params.module_name;
      this.searchParams = params.searchParams;
      this.getManualPaymentsList();
      this.showAdvSearchModal = false;
    },
    // openApplyReceipt(flag, item) {
    //   this.showApplyReceiptDetailsForm = flag;
    //   this.applyReceiptRowData = item;
    // },
    showHideAdvSearchModal(flag) {
      // To open advance search modal
      this.showAdvSearchModal = flag;
    },
    showHideVoidModal(flag) {
      // To open void moadal
      this.showAlert = false;
      this.isSuccess = false;
      this.responseMsg = '';
      this.voidForm.reason = null;
      this.showVoidModal = flag;
      this.voidForm.selectedModule.id = store.state.shared.moduleId;
      this.getDefaultDate(this.voidForm.selectedModule.id);
      if (!flag) {
        this.getManualPaymentsList();
      }
    },
    mainSearch() {
      this.searchParams = null;
      this.getManualPaymentsList();
    },
    openModuleBasedProject(moduleName) {
      if (moduleName === 'FMS') {
        this.openValueSetModal(appStrings.VALUESETTYPE.FMS_PROJECT);
      } else if (moduleName === 'Lease Management') {
        this.openValueSetModal(appStrings.VALUESETTYPE.LEASE_PROJECT_LIST);
      } else if (moduleName === 'Receivables') {
        this.openValueSetModal(appStrings.VALUESETTYPE.LEASE_PROJECT_LIST);
      }
    },
    getAccountingDataById(paymentDtl, payments) {
      this.apHdrId = paymentDtl.ap_payment_hdr_id;
      this.loader = true;
      this.$store
        .dispatch(
          'manualPayments/getPaymentAccountingDataById',
          this.apHdrId
      )
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            paymentDtl.accountingList = response.data.data;
            this.accountingListData = paymentDtl.accountingList;
            this.invoiceListData = [];
            payments.toggleDetails();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.selectedModule.id) {
        this.selectedModule = {
          name: null,
          id: null
        };
      } else if (vsetCode === this.selectedLegalEntity.value) {
        this.selectedLegalEntity = {
          text: null,
          value: null
        };
      }
    },
    showHideAddManualPaymentModal(flag) {
      this.showAddManualPaymentModal = flag;
    },
    rowSelected(item) {
      this.manualPaymentDetails = item;
      this.showHideAddManualPaymentModal(true);
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.paymentDate);
    },
    downloadAccountingData(){
    this.loader = true;
      const downloadpayload = this.apHdrId;
      this.downloadExcel.downloadDetails(
        downloadpayload,
        'manualPayments/getPaymentAccountingDataById',
        'Accounting Data',
        () => (this.loader = false)
      );
      }
  },
  beforeDestroy() {
    this.unsubscribe();
    this.$store.dispatch('manualPayments/saveAdvSearchFilters', null);
    // this.$store.state.manualPayments.manualPayments.adv_search = null;
  }
};
